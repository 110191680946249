/*
 *  * |///////////////////////////////////////////////////////////////////////|
 *  * |                                                                       |
 *  * | Copyright (C) STELLABLUE INTERACTIVE SERVICES PVT. LTD.               |
 *  * | All Rights Reserved                                                   |
 *  * |                                                                       |
 *  * | This document is the sole property of StellaBlue Interactive          |
 *  * | Services Pvt. Ltd.                                                    |
 *  * | No part of this document may be reproduced in any form or             |
 *  * | by any means - electronic, mechanical, photocopying, recording        |
 *  * | or otherwise - without the prior written permission of                |
 *  * | StellaBlue Interactive Services Pvt. Ltd.                             |
 *  * |                                                                       |
 *  * |///////////////////////////////////////////////////////////////////////|
 *  */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrlNDHM: 'https://application.projectmamata.com:8443/',
  apiUrl:  'https://application.projectmamata.com:8443/',
  environment_identifier: '',
  environment_identifier_background_color: '#4dbeac',
  GOOGLE_CLIENT_ID: '819126560466-s7qnpj677rgdfbe7u0a2ht2i2tsemuo1.apps.googleusercontent.com',
  FACEBOOK_APP_ID: '2238273849761169',
  TRANSLATION_FILE_PATH: './assets/i18n/',
  DATE_FORMAT:'DD-MM-YYYY',
  AUTO_SAVE_PRESCRIPTION_TIME: 20000,
  AUTO_SAVE_PRESCRIPTION_FLAG: true,
  GOOGLE_API_KEY: 'AIzaSyBWVyk6Tdm6Hpl_nA_IssRZFoxGMXjM1dU',
  awskeyPrefix: "projectmamataprd/",
  awsbucket: "prodmamata2022",
  awsregion: "ap-south-1",
//  awsaccessKey: "AKIA44UHLYUDS4AYJJW7",
//  awssecretKey: "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",
  awsaccessKey: "AKIA44UHLYUD2GWYRE6B",
  awssecretKey: "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",
  awssuccessActionStatus: 201,
  awsS3PathPrefix: "https://prodmamata2022.s3.ap-south-1.amazonaws.com/projectmamataprd/",
  FIREBASE_CONFIG : {
    apiKey: "AIzaSyBUkd6xD1BoVSMjENF2OyP7vqFpFBem6N0",
    authDomain: "mamata-351406.firebaseapp.com",
    projectId: "mamata-351406",
    storageBucket: "mamata-351406.appspot.com",
    messagingSenderId: "845751526072",
    appId: "1:845751526072:web:257b74ffdc32bcf7550a4d"
  },
  firebase_FCM: {
    
    apiKey: "AIzaSyBUkd6xD1BoVSMjENF2OyP7vqFpFBem6N0",
    authDomain: "mamata-351406.firebaseapp.com",
     databaseURL: "https://mamata-351406.firebaseio.com",
     projectId: "mamata-351406",
     storageBucket: "",
     messagingSenderId: "845751526072",
     appId: "1:845751526072:web:257b74ffdc32bcf7550a4d"
   },
   startingPageNo: 0
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
